import React from "react";

const ListHeader = () => {
  return (
    <div className="list-header is-hidden-small">
      <div>Société</div>
      <div>Nom</div>
      <div>Fonction</div>
      <div>Email</div>
      <div>Portable</div>
      <div>fixe</div>
      <div>Wildix</div>
      <div>Localisation</div>
    </div>
  );
};
export default ListHeader;
