import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {ReactComponent as GoogleLogo} from '../../assets/img/Google_G_Logo.svg';

function Login() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if ( user && user.email.match(/@sporting-groupe\.fr|@sporting-comm\.fr|@sporting-form\.fr|@sporting-eat\.fr|@sporting-house\.fr|@sporting-works\.fr|@sporting-immobilier\.fr|@sporting-promotion\.fr|@sporting-facilities\.fr$/i) ) navigate("/");

  }, [user, loading]);
  return (
    <div className="login">
      <div className="login__container">
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          <GoogleLogo />
          Se connecter avec Google
        </button>
      </div>
    </div>
  );
}
export default Login;