import { useEffect, useRef, useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { formatUsers } from "../../utils/utils";
import EmployeeChart from "../Tree";
import styled from "styled-components";
import { RxCross2 } from "react-icons/rx";
import { useDraggable } from "react-use-draggable-scroll";
import UserModal from "../UserModal";
import { formatClass } from "../../utils/utils";
import UsersContext from "../UsersContext";

const OrganigrammePage = () => {
  const pageMotion = {
    initial: { opacity: 0, x: "-30%" },
    animate: { opacity: 1, x: 0, transition: { duration: 0.7 } },
    exit: { opacity: 0, x: "-30%", transition: { duration: 0.7 } },
  };

  const modalMotion = {
    initial: { x: "100%" },
    animate: { x: 0, transition: { duration: 0.7 } },
    exit: { x: "100%", transition: { duration: 0.7 } },
  };

  const StyledNode = styled.div`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid red;
  `;

  const ref = useRef();
  const { events } = useDraggable(ref, {
    isMounted: true,
    applyRubberBandEffect: true,
  });

  const [tree, setTree] = useState([]);
  const [topLevelUser, setTopLevelUser] = useState({});

  const [modalUser, setModalUser] = useState({});

  const [users, setUsers] = useState([]);
  const usersContext = useContext(UsersContext);

  useEffect(() => {
    setUsers(formatUsers(usersContext));
  }, [usersContext]);

  useEffect(() => {
    const tempTopUser = users.filter((user) => {
      return user.primaryEmail == "m.merz@sporting-groupe.fr";
    });
    setTopLevelUser(tempTopUser[0]);

    const arrayToTree = (users, parent = null) =>
      users
        .filter((item) => item.parent === parent)
        .map((child) => ({
          ...child,
          children: arrayToTree(users, child.primaryEmail),
        }));
    setTree(arrayToTree(users, tempTopUser[0]?.primaryEmail));
  }, [users]);

  const handleClickCard = (e, user) => {
    // console.log(user);
    if (modalUser?.name?.fullName === user?.name?.fullName) {
      setModalUser({});
    } else {
      setModalUser(user);
    }
  };

  return (
    tree &&
    topLevelUser && (
      <>
        <motion.div
          className="content-organigramme list-holder"
          {...events}
          ref={ref}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageMotion}
          transition={{ duration: 0.7 }}
        >
          <div>
            <div style={{ marginBottom: "5rem" }}></div>
            <EmployeeChart
              tree={tree}
              topLevelUser={topLevelUser}
              propsModal={{ handleClickCard: handleClickCard }}
            />
          </div>
        </motion.div>
        <AnimatePresence>
          {Object.keys(modalUser).length > 0 && (
            <motion.div
              key={modalUser.id}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={modalMotion}
              transition={{ duration: 0.7 }}
              className="modal orga"
            >
              <div className="modal-content">
                <button
                  className={`light-hover-${formatClass(
                    modalUser?.orgUnitPath
                  )}`}
                  onClick={() => setModalUser({})}
                >
                  <RxCross2 />
                </button>
                <UserModal user={modalUser} />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    )
  );
};

export default OrganigrammePage;
