import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function RequireAuth() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (
      !user ||
      !user.email.match(
        /@groupe-sporting\.fr|@sporting-groupe\.fr|@sporting-comm\.fr|@sporting-form\.fr|@sporting-village\.fr|@sporting-eat\.fr|@sporting-house\.fr|@sporting-works\.fr|@sporting-immobilier\.fr|@sporting-promotion\.fr|@sporting-facilities\.fr$/i
      )
    )
      navigate("/login");
  }, [user, loading, navigate]);

  return user && <Outlet />;
}
export default RequireAuth;
