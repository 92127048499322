import { useEffect, useState } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import RequireAuth from "./components/RequireAuth";
import AnnuairePage from "./components/page/AnnuairePage";
import OrganigrammePage from "./components/page/OrganigrammePage";
import Login from "./components/page/Login";
import { UsersProvider } from "../src/components/UsersContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import userJSON from "./data/users.json"

import { useNavigate } from "react-router-dom";

const App = (props) => {
  let location = useLocation();
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://annuaire.sporting-groupe.fr/data/users.json");
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        setUsers(userJSON);
        //console.error(error);
      }
    }

    if (
      !user ||
      !user.email.match(
        /@groupe-sporting\.fr|@sporting-groupe\.fr|@sporting-comm\.fr|@sporting-form\.fr|@sporting-village\.fr|@sporting-eat\.fr|@sporting-house\.fr|@sporting-works\.fr|@sporting-immobilier\.fr|@sporting-promotion\.fr|@sporting-facilities\.fr$/i
      )
    ) {
      navigate("/login");
      return
    }
    if( users?.length < 1 ) fetchData()

  }, [navigate]);

  return (
    <AnimatePresence mode="wait">
      <UsersProvider value={users}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<RequireAuth />}>
            <Route path="/" element={<AnnuairePage />} />
            <Route path="organigramme" element={<OrganigrammePage />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </UsersProvider>
    </AnimatePresence>
  );
};

export default App;
