import { useEffect, useState, useContext } from "react";
import { formatUsers, fetchUsers } from "../../utils/utils";
import { motion, AnimatePresence } from "framer-motion";
import SingleUser from "../SingleUser";
import ListHeader from "../ListHeader";
import Filters from "../Filters";
import UserModal from "../UserModal";
import { RxCross2 } from "react-icons/rx";
import { formatClass } from "../../utils/utils";
import UsersContext from "../UsersContext";
import Loader from "../Loader";

const AnnuairePage = () => {
  const pageMotion = {
    initial: { opacity: 0, x: "-30%" },
    animate: { opacity: 1, x: 0, transition: { duration: 0.7 } },
    exit: { opacity: 0, x: "-30%", transition: { duration: 0.7 } },
  };
  const modalMotion = {
    initial: { x: "100%" },
    animate: { x: 0, transition: { duration: 0.7 } },
    exit: { x: "100%", transition: { duration: 0.7 } },
  };

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [modalUser, setModalUser] = useState({});

  const [users, setUsers] = useState([]);
  const usersContext = useContext(UsersContext);

  useEffect(() => {
    setUsers(formatUsers(usersContext));
  }, [usersContext]);

  const handleNewUsers = (users) => {
    setFilteredUsers(users);
  };

  const handleClick = (user) => {
    // console.log(user);
    if (modalUser?.name?.fullName === user?.name?.fullName) {
      setModalUser({});
    } else {
      setModalUser(user);
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
      transition={{ duration: 0.7 }}
      className="list-holder"
    >
      <Filters users={users} handleNewUsers={handleNewUsers} />

      <AnimatePresence>
        {users && users.length > 0 ? (
          <div className="users-list">
            <ListHeader />
            {filteredUsers.map((user, i) => {
              return (
                <SingleUser
                  handleClick={handleClick}
                  isSelected={
                    modalUser?.name?.fullName === user?.name?.fullName
                  }
                  user={user}
                  key={i}
                />
              );
            })}
          </div>
        ) : (
          <Loader />
        )}

        {Object.keys(modalUser).length > 0 && (
          <motion.div
            key={modalUser.id}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={modalMotion}
            transition={{ duration: 0.7 }}
            className="modal"
          >
            <div className="modal-content">
              <button
                className={`light-hover-${formatClass(modalUser?.orgUnitPath)}`}
                onClick={() => setModalUser({})}
              >
                <RxCross2 />
              </button>
              <UserModal user={modalUser} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default AnnuairePage;
