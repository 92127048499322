import React from "react";
import { useEffect, useState } from "react";
import { formatClass } from "../utils/utils";
import { BsEnvelope } from "react-icons/bs";
import { BiMobileAlt } from "react-icons/bi";
import { AiOutlinePhone } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const UserModal = ({ user }) => {
  const pageMotion = {
    initial: { opacity: 0, x: "-30%" },
    animate: { opacity: 1, x: 0, transition: { duration: 0.7 } },
    exit: { opacity: 0, x: "-30%", transition: { duration: 0.7 } },
  };

  useEffect(() => {
    // console.log(user.emails);
  }, [user]);

  return (
    // <motion.div
    //   initial="initial"
    //   animate="animate"
    //   exit="exit"
    //   variants={pageMotion}
    //   transition={{ duration: 0.7 }}
    // >
    <div className={`user-single ${formatClass(user?.orgUnitPath)}`}>
      <div
        className={`user-single-header bg-${formatClass(user?.orgUnitPath)}`}
      >
        <span>{user?.orgUnitPath}</span>
        <span>
          {user?.locations?.length > 0 && user.locations[0].buildingId}
        </span>
      </div>
      <img
        src={
          "data:image/jpeg;base64," +
          user?.photo?.replace(/_/g, "/").replace(/-/g, "+")
        }
      />
      <div className="user-single-info">
        <span className="name">
          {user?.name?.givenName} {user?.name?.familyName.toUpperCase()}
        </span>
        <span className="orga">
          {user?.organizations?.length > 0 ? user.organizations[0].title : ""}
        </span>
        <span className={`is-${formatClass(user?.orgUnitPath)}`}>
          {user?.organizations?.length > 0 && user.organizations[0].department}
        </span>
      </div>
      {/* <!-- 
        * Add link to Google Chat using Gaia ID
        * https://stackoverflow.com/questions/56326138/direct-link-to-google-chat-dm
        * https://stackoverflow.com/questions/27028322/how-to-look-up-user-information-from-google-gaia-id/56442288#56442288
      --> */}
      <div className="user-single-link">
        <a
          className={`light-hover-${formatClass(user?.orgUnitPath)}`}
          href={`mailto:${user?.primaryEmail}`}
        >
          <BsEnvelope className={`is-${formatClass(user?.orgUnitPath)}`} />{" "}
          Email
        </a>

        <a
          href={`tel:${user?.mobile}`}
          className={`${user?.mobile ? "" : "hidden"} light-hover-${formatClass(
            user?.orgUnitPath
          )}`}
        >
          <BiMobileAlt className={`is-${formatClass(user?.orgUnitPath)}`} />{" "}
          Mobile
        </a>

        <a
          className={`light-hover-${formatClass(user?.orgUnitPath)}`}
          href={`tel:${user?.home}`}
        >
          <AiOutlinePhone className={`is-${formatClass(user?.orgUnitPath)}`} />{" "}
          Fixe
        </a>
      </div>
      <div className="user-single-contact">
        {user?.primaryEmail && (
          <div>
            <span>Email</span>
            <span>{user?.primaryEmail}</span>
          </div>
        )}
        {user?.mobile && (
          <div>
            <span>Portable</span>
            <span>{user?.mobile}</span>
          </div>
        )}
        {user?.home && (
          <div>
            <span>Fixe</span>
            <span>{user?.home}</span>
          </div>
        )}
        {user?.work && (
          <div>
            <span>Wildix</span>
            <span>{user?.work}</span>
          </div>
        )}
      </div>
    </div>
    // </motion.div>
  );
};

export default UserModal;
