import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut
} from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBJ7ODzf1gXU8M7kWytqEPqsXEhZP2HnPA",
    authDomain: "annuaire-contact-370708.firebaseapp.com",
    projectId: "annuaire-contact-370708",
    storageBucket: "annuaire-contact-370708.appspot.com",
    messagingSenderId: "753332751756",
    appId: "1:753332751756:web:75747358e5dcc779d39aa9",
    measurementId: "G-LMMF1HKHKJ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    return res.user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};

export {
  auth,
  signInWithGoogle,
  logout,
};