import * as React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import $ from "jquery";
import scrollto from "jquery.scrollto";
import { formatClass } from "../utils/utils";
import { motion, AnimatePresence } from "framer-motion";
import Thumbnail from "./Thumbnail";

const StyledNode = styled.div`
  display: inline-block;
`;

const TreeCard = ({ tree, propsModal }) => {
  useEffect(() => {
    $(".niv-2").addClass("closed");
  }, []);

  const handleClickButton = (e) => {
    if ($(e.target).closest("li > ul")) {
      $(e.target).closest("li").toggleClass("closed");
      $(".content-organigramme").scrollTo($(e.target).closest("li"), {
        duration: 500,
      });
    }
  };

  return (
    tree &&
    tree.length && (
      <>
        {tree.map((user) => (
          <TreeNode
            className={`${user.children.length == 0 ? "empty-node" : ""} niv-${
              user?.niv
            } `}
            label={
              <StyledNode>
                <div
                  className={`organigramme-card ${formatClass(
                    user?.orgUnitPath
                  )} border-${formatClass(user?.orgUnitPath)} ${
                    user?.children?.length > 0 ? "has-child" : ""
                  }`}
                >
                  <div
                    className={`content bg-${formatClass(user?.orgUnitPath)}`}
                    onClick={(e) => propsModal.handleClickCard(e, user)}
                  >
                    <Thumbnail
                      content={user?.photo}
                      alt={user?.name?.givenName}
                    />
                    <span>{user?.name?.givenName}</span>
                    <span>{user?.name?.familyName.toUpperCase()}</span>
                    <span className="title">
                      {user.organizations && user.organizations.length > 0
                        ? user.organizations[0].title
                        : ""}
                    </span>
                  </div>
                  {user?.children?.length ? (
                    <div className="button-deploy" onClick={handleClickButton}>
                      <BsChevronDown
                        className={`button-down is-${formatClass(
                          user?.orgUnitPath
                        )}`}
                      />
                      <BsChevronUp
                        className={`button-up is-${formatClass(
                          user?.orgUnitPath
                        )}`}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </StyledNode>
            }
            key={user.id}
          >
            {user?.children?.length > 0 && (
              <TreeCard tree={user?.children} propsModal={propsModal} />
            )}
          </TreeNode>
        ))}
      </>
    )
  );
};
const EmployeeChart = ({ tree, topLevelUser, propsModal }) => {
  return (
    topLevelUser &&
    tree && (
      <Tree
        lineWidth={"2px"}
        lineColor={"#374d5c"}
        lineBorderRadius={"10px"}
        label={
          <StyledNode>
            {
              <div className="organigramme-card top-user">
                <div
                  className="content"
                  onClick={(e) => propsModal.handleClickCard(e, topLevelUser)}
                >
                  <Thumbnail
                    content={topLevelUser?.photo}
                    alt={topLevelUser?.name?.givenName}
                  />
                  <span>{topLevelUser?.name?.givenName}</span>
                  <span>{topLevelUser?.name?.familyName.toUpperCase()}</span>
                  <span className="title">
                    {topLevelUser.organizations &&
                    topLevelUser.organizations.length > 0
                      ? topLevelUser.organizations[0].title
                      : ""}
                  </span>
                </div>
              </div>
            }
          </StyledNode>
        }
      >
        <TreeCard tree={tree} propsModal={propsModal} />
      </Tree>
    )
  );
};
export default EmployeeChart;
