import { useEffect, useState } from "react";
import Select from "react-select";
import { formatSearch } from "../utils/utils";

export default function Filters({ users, handleNewUsers }) {
  const [optionsOrga, setOptionsOrga] = useState([]);
  const [optionsSite, setOptionsSite] = useState([]);
  const [optionsSort, setOptionsSort] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedOptionsOrga, setSelectedOptionsOrga] = useState([]);
  const [selectedOptionsSite, setSelectedOptionsSite] = useState([]);
  const [selectedOptionsSort, setSelectedOptionsSort] = useState([]);
  const [nameValue, setNameValue] = useState("");

  const filterSite = (usersArr, filter) => {
    return !filter.length
      ? usersArr
      : usersArr.filter((user) => {
          let isReturned = false;
          filter.map((society) => {
            if (
              user.locations &&
              user.locations.length > 0 &&
              society.value === user.locations[0].buildingId
            )
              isReturned = true;
          });
          return isReturned;
        });
  };

  const filterOrga = (usersArr, filter) => {
    return !filter.length
      ? usersArr
      : usersArr.filter((user) => {
          let isReturned = false;
          filter.map((orga) => {
            if (orga.value === user.orgUnitPath) isReturned = true;
          });
          return isReturned;
        });
  };

  const filterName = (usersArr, filter) => {
    return !filter
      ? usersArr
      : usersArr.filter((user) => {
          return (
            user.name &&
            formatSearch(user.name.fullName).includes(formatSearch(filter))
          );
        });
  };

  const sortUsers = (usersArr, filter) => {
    return !filter
      ? usersArr
      : [].concat(usersArr).sort((a, b) => {
          if (filter.value == "name" && a.name && b.name) {
            let aName = a["name"]["familyName"];
            let bName = b["name"]["familyName"];
            return aName.localeCompare(bName);
          }
          if (
            filter.value == "location" &&
            a.locations?.length &&
            b.locations?.length
          ) {
            let aName = a["locations"][0]["buildingId"] ?? "zzzzzzzzzzzzzzzzz";
            let bName = b["locations"][0]["buildingId"] ?? "zzzzzzzzzzzzzzzzz";
            return aName.localeCompare(bName);
          }
          if (filter.value == "orga" && a.orgUnitPath && b.orgUnitPath) {
            let aName = a["orgUnitPath"];
            let bName = b["orgUnitPath"];
            return aName.localeCompare(bName);
          }
        });
  };

  useEffect(() => {
    // console.log(users)
    let uniqOrga = [...new Set(users.map((el) => el.orgUnitPath))];
    let uniqSite = [
      ...new Set(
        users.map((el) => {
          if (el.locations && el.locations.length > 0) {
            return el.locations[0].buildingId;
          } else {
            return "";
          }
        })
      ),
    ];

    setOptionsOrga(
      uniqOrga
        .map((orga) => {
          return {
            value: orga,
            label: orga,
          };
        })
        .filter((orga) => {
          return orga.label;
        })
    );

    setOptionsSite(
      uniqSite
        .map((society) => {
          return {
            value: society,
            label: society,
          };
        })
        .filter((society) => {
          return society.label;
        })
    );

    setOptionsSort([
      // {
      //   value: false,
      //   label: "",
      // },
      {
        value: "name",
        label: "Nom",
      },
      {
        value: "location",
        label: "Localisation",
      },
      {
        value: "orga",
        label: "Société",
      },
    ]);

    setFilteredUsers(users);
    handleNewUsers(users);
  }, [users]);

  useEffect(() => {
    let filteredArr = users;
    filteredArr = filterSite(filteredArr, selectedOptionsSite);
    filteredArr = filterOrga(filteredArr, selectedOptionsOrga);
    filteredArr = filterName(filteredArr, nameValue);
    filteredArr = sortUsers(filteredArr, selectedOptionsSort);
    setFilteredUsers(filteredArr);
    handleNewUsers(filteredArr);
  }, [
    selectedOptionsOrga,
    selectedOptionsSite,
    nameValue,
    selectedOptionsSort,
  ]);

  return (
    <div className="filter">
      <div className="users-input">
        <input
          type="text"
          name="name"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
          placeholder="Entrer un nom..."
          autocomplete="off"
        />
        <Select
          isMulti
          placeholder={"Sélectionner une société..."}
          options={optionsOrga}
          onChange={(options) => setSelectedOptionsOrga(options)}
        />
        <Select
          isMulti
          placeholder={"Sélectionner une localisation..."}
          options={optionsSite}
          onChange={(options) => setSelectedOptionsSite(options)}
        />
        <Select
          placeholder={"Trier par..."}
          options={optionsSort}
          onChange={(options) => setSelectedOptionsSort(options)}
        />
      </div>
    </div>
  );
}
