import React from "react";
import Navbar from "./Navbar";
import logo from "../assets/img/SPORTING_GROUPE-LOGO-BLANC-HORIZONTAL.png";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <Link to={`/`}>
        <img src={logo} />
      </Link>
      <Navbar />
    </header>
  );
};
export default Header;
