import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  let location = useLocation();

  return (
    <ul id="menu">
      {/* icon from heroicons.com */}
      {/* <button className="hamburger">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
                >
                <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                />
                </svg>
            </button> */}
      <li className={`${location?.pathname == "/" && "selected"}`}>
        <Link className={`button`} to={`/`}>
          Annuaire
        </Link>
      </li>
      <li className={`${location?.pathname == "/organigramme" && "selected"}`}>
        <Link className={`button`} to={`/organigramme`}>
          Organigramme
        </Link>
      </li>
      <li>
        <a
          className="button"
          href="https://sites.google.com/sporting-facilities.fr/intranet-sporting/accueil"
        >
          Intranet
        </a>
      </li>
    </ul>
  );
}
