import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from '../logo-groupe.svg';

const Thumbnail = (props) => {
    const { content, alt } = props

    if ( content ) {
        return (
            <img src={`data:image/jpeg;base64,${content}`} alt={alt}/>
        )
    }
    
    else {
        return (
            <Logo className="default-thumbnail"/>
        )
    }
}
export default Thumbnail;