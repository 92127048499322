// module.exports = {

//     // formate l'input name
//     formatSearch: function(str) {
//         return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
//     },

//     // formate la class orga
//     formatClass: function(str) {
//         return str.replaceAll("/Commerciaux", "").toLowerCase().replaceAll("/", " ").replaceAll(" ", "-")
//     },

//     // formate la donnée reçu de l'API
//     formatUsers: function(users) {
//         let newUsers = users.map( user => {

//         let niv = 0
//         let work, home, mobile
//         let orgUnitPath = user.orgUnitPath

//         let hasPhones = user.phones && user.phones.length > 0

//         // Formate les numéro de téléphone
//         if( hasPhones && user.phones.filter(phone => phone.type === 'work').length > 0 )
//             work = user.phones.filter(phone => phone.type === 'work')[0].value
//         if( hasPhones && user.phones.filter(phone => phone.type === 'home').length > 0 )
//             home = user.phones.filter(phone => phone.type === 'home')[0].value
//         if( hasPhones && user.phones.filter(phone => phone.type === 'mobile').length > 0 )
//             mobile = user.phones.filter(phone => phone.type === 'mobile')[0].value

//         // ajoute la société
//         if(user.primaryEmail.includes('form.fr')) orgUnitPath = 'Sporting Form'
//         if(user.primaryEmail.includes('comm.fr')) orgUnitPath = 'Sporting Comm'
//         if(user.primaryEmail.includes('promotion.fr')) orgUnitPath = 'Sporting Promotion'

//         if(user?.primaryEmail === 'm.merz@sporting-groupe.fr') niv = 1

//         return {
//             ...user,
//             orgUnitPath: orgUnitPath.replace('/', ''),
//             work: work,
//             home: home,
//             mobile: mobile,
//             niv: niv,
//             photo: user.photo?.replace(/_/g,'/').replace(/-/g,'+'),
//             parent: user?.relations?.length > 0 ? user.relations[0].value : "",
//             locations: !user.locations ? [{buildingId: null}] : user.locations
//         }})
//         // Filtre les comptes pas remontés dans l'annuaire
//         .filter(user => {
//         return !user?.orgUnitPath.includes('Ancien') &&
//         !user.orgUnitPath.includes('Outil') &&
//         !user.orgUnitPath.includes('Externe') &&
//         !user.name.familyName.includes('Informatique')
//         })
//         // Tri par société
//         .sort( (a,b) => a.orgUnitPath.localeCompare(b.orgUnitPath))

//         // ajoute un niveau pour l'organigramme
//         for(let i = 2; i < 5; i++) {
//         newUsers.forEach((user, ind) => {
//             let relation = ""
//             if(user.relations && user.relations.length > 0) {
//             relation = newUsers.filter( u => u.primaryEmail === user.relations[0].value)[0]
//             if(relation && relation?.niv === (i - 1))
//                 newUsers[ind].niv = i
//             }
//         });
//         }

//         return newUsers
//     }
// }

// formate l'input name
export function formatSearch(str) {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

// formate la class orga
export function formatClass(str) {
  return str
    .replaceAll("/Commerciaux", "")
    .toLowerCase()
    .replaceAll("/", " ")
    .replaceAll(" ", "-");
}

// formate la donnée reçu de l'API
export function formatUsers(users) {
  let newUsers = users
    .map((user) => {
      let niv = 0;
      let work, home, mobile;
      let orgUnitPath = user?.orgUnitPath.replaceAll(/\/Commerciaux$/g, "");

      let hasPhones = user.phones && user.phones.length > 0;

      // Formate les numéro de téléphone
      if (
        hasPhones &&
        user.phones.filter((phone) => phone.type === "work").length > 0
      )
        work = user.phones.filter((phone) => phone.type === "work")[0].value;
      if (
        hasPhones &&
        user.phones.filter((phone) => phone.type === "home").length > 0
      )
        home = user.phones.filter((phone) => phone.type === "home")[0].value;
      if (
        hasPhones &&
        user.phones.filter((phone) => phone.type === "mobile").length > 0
      )
        mobile = user.phones.filter((phone) => phone.type === "mobile")[0]
          .value;

      // ajoute la société
      if (user.primaryEmail.includes("form.fr")) orgUnitPath = "Sporting Form";
      if (user.primaryEmail.includes("comm.fr")) orgUnitPath = "Sporting Comm";
      if (user.primaryEmail.includes("promotion.fr"))
        orgUnitPath = "Sporting Promotion";

      if (user?.primaryEmail === "m.merz@sporting-groupe.fr") niv = 1;

      return {
        ...user,
        orgUnitPath: orgUnitPath.replace("/", ""),
        work: work,
        home: home,
        mobile: mobile,
        niv: niv,
        photo: user.photo?.replace(/_/g, "/").replace(/-/g, "+"),
        parent: user?.relations?.length > 0 ? user.relations[0].value : "",
        locations: !user.locations ? [{ buildingId: null }] : user.locations,
      };
    })
    // Filtre les comptes pas remontés dans l'annuaire
    // .filter((user) => {
    //   return (
    //     !user?.orgUnitPath.includes("Ancien") &&
    //     !user.orgUnitPath.includes("Outil") &&
    //     !user.orgUnitPath.includes("Externe") &&
    //     !user.name.familyName.includes("Informatique")
    //   );
    // })
    // Tri par société
    .sort((a, b) => a.orgUnitPath.localeCompare(b.orgUnitPath));

  // ajoute un niveau pour l'organigramme
  for (let i = 2; i < 5; i++) {
    newUsers.forEach((user, ind) => {
      let relation = "";
      if (user.relations && user.relations.length > 0) {
        relation = newUsers.filter(
          (u) => u.primaryEmail === user.relations[0].value
        )[0];
        if (relation && relation?.niv === i - 1) newUsers[ind].niv = i;
      }
    });
  }

  return newUsers;
}
