import { formatClass } from "../utils/utils";
import Thumbnail from "./Thumbnail";

export default function SingleUser({ user, handleClick, isSelected }) {
  return (
    <div
      className={`card user ${formatClass(
        user?.orgUnitPath
      )} light-hover-${formatClass(user?.orgUnitPath)} before-${formatClass(
        user?.orgUnitPath
      )} ${isSelected ? "selected" : ""}`}
      key={user.id}
      onClick={() => handleClick(user)}
    >
      <div
        className={`is-hidden-small org-unit is-${formatClass(
          user?.orgUnitPath
        )}`}
      >
        {user?.orgUnitPath}
      </div>
      <div className="name">
        <Thumbnail content={user?.photo} alt={user?.name?.givenName} />
        {user?.name && (
          <span>
            {user?.name?.givenName} {user?.name?.familyName.toUpperCase()}
          </span>
        )}
      </div>
      <div className="title">
        {user.organizations && user.organizations.length > 0
          ? user.organizations[0].title
          : ""}
      </div>
      <div className="email">{user?.primaryEmail}</div>
      <div className="phone mobile is-hidden-small">{user?.mobile}</div>
      <div className="phone home">{user?.home}</div>
      <div className="phone work is-hidden-small">{user?.work}</div>
      <div className="location">
        {user?.locations?.length > 0 && user.locations[0].buildingId}
      </div>
    </div>
  );
}
