import React from 'react';
import { ReactComponent as Logo } from '../logo-groupe.svg';


const Loader = () => {
    return (
      <div className="loader-logo">
        <Logo />
      </div>
    );
  }
  export default Loader;